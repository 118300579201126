import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Artist from "../components/artist"
import Painting from "../components/painting"
import loadable from "@loadable/component"

const DaVinciComponent = loadable(() => import("../components/daVinciComponent"))

const DaVinci = ({ data }) => {
  return (

    <Layout>
      <SEO
        title="Leonardo da Vinci"
        description="Leonardo da Vinci life and works"
        image="/logo.png"
        pathname="/daVinci"

      />

      <Artist
        bubble2={{ "--bubble2": "#dc7412" }}
        name={"Leonardo da Vinci"}
        dates={"(1452-1519)"}
        descrip={"Da Vinci was a painter, scientist, mathematician, sculptor, architect, inventor, and engineer. He is probably the most famous example of a Renaissance man; this is someone who excels in many different fields. "}
      />

      <Painting
        leftPanel={{ backgroundColor: "#6f1a0f" }}
        h1Title={"Self Portrait"}
        sectionDescription={"The Renaissance was a period of great cultural and scientific activity in western Europe, especially Italy. Leonardo da Vinci was born on April 15, 1452, near Vinci, in what is now Italy.  This is when the Italian Renaissance peaked. "}
        paintingFixed={data.selfportrait.childImageSharp.fixed}
        alt={"da Vinci Self Portrait"}
        rtPanel={{ backgroundColor: "#e0a369" }}
        // Mobile
        paintingFluid={data.selfportraitmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#6f1a0f" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#68748c" }}
        h1Title={"Verrocchio's Baptism of Christ"}
        sectionDescription={"When da Vinci was 15, he began studying under the artist Andrea del Verrocchio in Florence. Verrocchio taught him about painting, sculpture, and the design of mechanical devices.  In Verrocchio's Baptism of Christ, the kneeling angel at the left of the painting was painted by da Vinci when he was 22 years old. The angel shows da Vinci's promise as a painter."}
        paintingFixed={data.baptismofchrist.childImageSharp.fixed}
        alt={"Verrocchio's Baptism of Christ"}
        rtPanel={{ backgroundColor: "#63666c" }}
        paintingFluid={data.baptismofchristmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#68748c" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#3a514d" }}
        h1Title={"Mona Lisa"}
        sectionDescription={"Although he didn't produce a lot of paintings, he had a huge influence on other painters and his work is known around the world.  It’s likely that you know about the Mona Lisa.  In the painting, colors are blended seamlessly and da Vinci creates a smoky effect.  He also uses contrasts of light and dark to create a luminous, 3D effect.  He was also one of the first painters to use an imaginary landscape. "}
        paintingFixed={data.monalisa.childImageSharp.fixed}
        alt={"Mona Lisa"}
        rtPanel={{ backgroundColor: "#7e8f64" }}
        paintingFluid={data.monalisamobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#3a514d" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#36131b" }}
        h1Title={"Mona Lisa's Smile"}
        sectionDescription={"What people remember most about the Mona Lisa is her expression. Da Vinci wanted the painting to represent the idea of happiness, but her expression is usually described as a cross between a smile and a frown.  It has been reported that her sad smile was because she was married off to a Florentine slave trader at the age of 15.  The unfinished masterpiece did not turn Mona Lisa into an icon until centuries after her death. The French government says it will never sell the painting, but it is believed to be worth $800 million. "}
        paintingFixed={data.monalisacloseup.childImageSharp.fixed}
        alt={"Mona Lisa's Smile"}
        rtPanel={{ backgroundColor: "#270d13" }}
        // Mobile
        paintingFluid={data.monalisacloseup.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#36131b" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#435c79" }}
        h1Title={"The Last Supper"}
        sectionDescription={"You have probably also seen da Vinci’s The Last Supper depicting Jesus's last meal with his apostles before he was crucified.  It is a huge wall painting in an Italian monastery.  By the early 1500’s, the painting started to flake and decay.  Vibrations from bombings during World War II made it worse.  The painting has been restored so most of the original paint is gone. In 1652, a doorway was added to the wall which took out a huge chunk of the painting which included Jesus' feet. "}
        paintingFixed={data.lastsupper.childImageSharp.fixed}
        alt={"The Last Supper"}
        rtPanel={{ backgroundColor: "#d1795d" }}
        // Mobile
        paintingFluid={data.lastsuppermobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#435c79" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#252322" }}
        h1Title={"Vitruvian Man"}
        sectionDescription={"Da Vinci was one of the world's greatest anatomists. He dissected more than 30 human corpses.  His drawings of muscles, organs, bones, and fetuses advanced our understanding of human anatomy. The most famous of these sketches is the Vitruvian Man, from one of his private notebooks.  The sketch tries to portray “ideal” human proportions proposed by the ancient Roman architect Vitruvius. "}
        paintingFixed={data.vitruvianman.childImageSharp.fixed}
        alt={"Vitruvian Man"}
        rtPanel={{ backgroundColor: "#e9c88d" }}
        // Mobile
        paintingFluid={data.vitruvianmanmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#252322" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#dec091" }}
        h1Title={"Hummingbird Sketch"}
        sectionDescription={"A lot of what we know about da Vinci comes from his notes and drawings, thousands of pages of which still exist. They show da Vinci's fascination not only with anatomy, but with stars and planets, animals, architecture, geography, and fossils. "}
        paintingFixed={data.hummingbird.childImageSharp.fixed}
        alt={"Hummingbird Sketch"}
        rtPanel={{ backgroundColor: "#dfb55f" }}
        // dec091
        paintingFluid={data.hummingbirdmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#dec091" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#1a0e0a" }}
        h1Title={"The Great Kite"}
        sectionDescription={"While in Milan, da Vinci became interested in observing nature. He carefully and accurately drew objects as a way to study them.  Da Vinci drawings included architecture, machinery, painting, the human body, and the science of flight. "}
        paintingFixed={data.thegreatkite.childImageSharp.fixed}
        alt={"The Great Kite"}
        rtPanel={{ backgroundColor: "#eacfa9" }}
        // Mobile
        paintingFluid={data.thegreatkitemobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#1a0e0a" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#4a1728" }}
        h1Title={"The Flying Machine"}
        sectionDescription={"Some of his drawings planned inventions for things that couldn't possibly have been built at the time.  More than 300 years before flying machines were perfected, da Vinci developed plans for an airplane and a helicopter. He kept most of his drawings secret, so most of them weren't made public until after his death in 1519. It was a long time before anyone could decipher his notes. Da Vinci wrote in mirror script, which was written backwards."}
        paintingFixed={data.flyingmachine.childImageSharp.fixed}
        alt={"The Flying Machine "}
        rtPanel={{ backgroundColor: "#edeacd" }}
        // Mobile
        paintingFluid={data.flyingmachine.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#4a1728" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#493326" }}
        h1Title={"Anatomy Sketch"}
        sectionDescription={"Da Vinci wasn't crazy. He just wanted to protect his work. If he had shared his drawing and notes, he could have revolutionized science. He understood how important observation was long before other scientists did. His keen ability to observe led him to anticipate scientific discoveries like the circulation of blood and the effect of the moon on tides."}
        paintingFixed={data.anatomy.childImageSharp.fixed}
        alt={"Anatomy Sketch by da Vinci"}
        rtPanel={{ backgroundColor: "#31231d" }}
        // Mobile
        paintingFluid={data.anatomymobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#493326" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#1c0000" }}
        h1Title={"Unfinished: Adoration of the Magi"}
        sectionDescription={"Da Vinci once said, \"Art is never finished, only abandoned.\"  He is famous for starting far more paintings, inventions, and projects than he ever finished. "}
        paintingFixed={data.adorationofthemagi.childImageSharp.fixed}
        alt={"Unfinished: Adoration of the Magi"}
        rtPanel={{ backgroundColor: "#ca9d44" }}
        // Mobile
        paintingFluid={data.adorationofthemagimobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#1c0000" }}
      />

<DaVinciComponent/>

    </Layout>
  )
}

export default DaVinci

export const query = graphql`
  {
 selfportrait: file(relativePath: { eq: "paintings/davinci/selfportrait.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     selfportraitmobile: file(relativePath: { eq: "paintings/davinci/selfportraitmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
     baptismofchrist: file(relativePath: { eq: "paintings/davinci/baptismofchrist.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         baptismofchristmobile: file(relativePath: { eq: "paintings/davinci/baptismofchristmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         monalisa: file(relativePath: { eq: "paintings/davinci/monalisa.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         monalisamobile: file(relativePath: { eq: "paintings/davinci/monalisamobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         monalisacloseup: file(relativePath: { eq: "paintings/davinci/monalisacloseup.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         monalisacloseup: file(relativePath: { eq: "paintings/davinci/monalisacloseup.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         lastsupper: file(relativePath: { eq: "paintings/davinci/lastsupper.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         lastsuppermobile: file(relativePath: { eq: "paintings/davinci/lastsupper.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         vitruvianman: file(relativePath: { eq: "paintings/davinci/vitruvianman.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         vitruvianmanmobile: file(relativePath: { eq: "paintings/davinci/vitruvianmanmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         hummingbird: file(relativePath: { eq: "paintings/davinci/hummingbird.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         hummingbirdmobile: file(relativePath: { eq: "paintings/davinci/hummingbirdmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         thegreatkite: file(relativePath: { eq: "paintings/davinci/greatkite.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         thegreatkitemobile: file(relativePath: { eq: "paintings/davinci/greatkitemobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
             flyingmachine: file(relativePath: { eq: "paintings/davinci/flyingmachine.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         flyingmachine: file(relativePath: { eq: "paintings/davinci/flyingmachine.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }         anatomy: file(relativePath: { eq: "paintings/davinci/anatomy.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         anatomymobile: file(relativePath: { eq: "paintings/davinci/anatomymobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
            adorationofthemagi: file(relativePath: { eq: "paintings/davinci/adorationofthemagi.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         adorationofthemagimobile: file(relativePath: { eq: "paintings/davinci/adorationofthemagimobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`